@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.reviews {
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  &__item {
    display: grid;
    place-items: center;
    align-content: baseline;
    overflow: hidden;
    border-radius: var(--radius-main);
    padding: 24px;
    background-color: var(--color-white);

    p {
      font-size: 18px;
      color: var(--color-black);
    }
  }

  &__autor {
    text-align: center;

    &::after {
      content: "";
      position: relative;
      display: block;
      margin-bottom: 24px;
      width: 181px;
      height: 24px;
      background-image: url("../images/icons/stars.svg");
      background-position: 0 0;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  img {
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 27px;
  }

  h3 {
    margin-bottom: 24px;
  }

  @include media(tablet) {
    padding: 30px 0;

    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      padding: 16px;

      img {
        margin-bottom: 16px;
      }

      &:last-child {
        grid-column: 2 span;
        max-width: 100%;
      }
    }
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;
    }

    &__item {
      &:last-child {
        grid-column: auto;
      }
    }
  }
}
