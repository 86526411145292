@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.footer {
  padding: 40px 0;
  background-color: var(--color-blue-900);

  &__list {
    display: flex;
    gap: 24px;

    a {
      font-size: 16px;
      transition: color 0.3s linear;

      &:hover {
        color: var(--color-blue-300);
      }
    }
  }

  &__copy {
    display: flex;
    justify-content: space-between;

    p {
      font-size: 16px;
    }

    a {
      display: flex;
      align-items: center;
      gap: 8px;

      &::before {
        content: "";
        position: relative;
        width: 24px;
        height: 24px;
        background-image: url("../images/icons/mail.svg");
        background-position: 0 0;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }

  @include media(tablet) {
    padding: 24px 0;

    &__list {
      gap: 16px;

    }
  }

  @include media(mobile-l) {
    &__list {
      flex-wrap: wrap;
      justify-content: center;
    }

    &__copy {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 20px;
    }
  }
}
