@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.story {
  h2 {
    margin: 0 auto 48px;
    width: fit-content;
    text-align: center;
  }

  &__inner {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1090px;
    gap: 24px;
    margin-inline: auto;
  }

  &__desc {
    width: 100%;
    max-width: 644px;
    translate: 0 4px;

    p {
      margin-bottom: 24px;
    }
  }

  .btn {
    margin-left: auto;
    padding: 18px 36px;
  }

  &--about {
    padding-top: 56px;

    .story__inner {
      flex-direction: row-reverse;
    }

    .story__desc {
      max-width: 532px;

      p {
        margin-bottom: 0;
      }
    }
  }

  @include media(tablet) {
    &__inner {
      flex-direction: column;
      align-items: stretch;
    }

    &__desc {
      max-width: 100%;
      translate: none;
    }

    &__image {
      img {
        width: 100%;
        object-position: center;
        aspect-ratio: 16/9;
      }
    }

    h2 {
      margin-bottom: 30px;
    }

    .btn {
      min-width: 280px;
      margin-inline: auto;
    }

    &--about {
      padding: 30px 0;

      .story__inner {
        flex-direction: column;
        align-items: stretch;
      }

      .story__desc {
        max-width: 100%;
      }
    }
  }

  @include media(mobile-m) {
    .btn {
      max-width: 100%;
    }
  }
}
