@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.team {
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  &__item {
    overflow: hidden;
    border-radius: calc(var(--radius-big) + 12px);
  }

  &__desc {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 24px;
    height: 100%;
    color: var(--color-black);
    background-color: var(--color-white);

    span {
      display: block;
      margin-bottom: 26px;
      color: var(--color-blue-300);
    }

    p {
      font-size: 18px;
    }
  }

  img {
    overflow: hidden;
    border-radius: var(--radius-big);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    width: 100%;
  }

  h3 {
    margin-bottom: 8px;
  }

  @include media(tablet) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      &:last-child {
        grid-column: span 2;

        img {
          aspect-ratio: 2/1;
        }
      }
    }

    &__desc {
      padding: 16px;

      span {
        margin-bottom: 16px;
      }
    }
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;
    }

    &__item {
      img {
        object-position: center;
        aspect-ratio: 2/1;
      }

      &:last-child {
        grid-column: auto;
      }
    }
  }
}
