@import "https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
:root {
  --content-width: 1312px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Poppins", sans-serif;
  --font-family-secondary: "Merriweather", sans-serif;
  --font-family-third: "Oswald", sans-serif;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --fw-900: 900;
  --color-white: #fff;
  --color-black: #000;
  --color-blue-300: #31a0fee5;
  --color-blue-600: #394264;
  --color-blue-900: #252b42;
  --color-orange-600: #ec5c2e;
  --radius-main: 12px;
  --radius-big: 20px;
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  letter-spacing: 0;
  color: var(--color-white);
  background-color: var(--color-blue-600);
  font-optical-sizing: auto;
  scroll-padding: var(--header-height);
  font-size: clamp(16px, 1vw, 20px);
  font-style: normal;
  line-height: 1.5;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
  position: relative;
}

.page__body:before {
  content: "";
  z-index: -1;
  opacity: 0;
  background-color: #27282bbf;
  transition: opacity .3s;
  position: absolute;
  inset: 0;
}

.page.open {
  overflow: hidden;
}

.page.open > .page__body:before {
  z-index: 5;
  opacity: 1;
}

.section {
  padding: 3.75rem 0;
}

@media only screen and (max-width: 1180px) {
  .section {
    padding: 45px 0;
  }
}

@media only screen and (max-width: 992px) {
  .section {
    padding: 30px 0;
  }
}

.container, .wrapper, .contacts__wrapper {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.wrapper {
  max-width: 1122px;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.main--top {
  padding-top: calc(var(--header-height)  - 4px);
}

.logo {
  width: 100%;
  max-width: 243px;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .logo {
    max-width: 200px;
  }
}

.content {
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  margin: 0 auto 3rem;
}

@media only screen and (max-width: 992px) {
  .content {
    margin-bottom: 30px;
  }
}

.is-scrolling {
  scrollbar-color: var(--color-blue-300) var(--color-white);
  scrollbar-width: thin;
  overflow: hidden;
}

.is-scrolling::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.is-scrolling::-webkit-scrollbar-track {
  background-color: var(--color-white);
  border-radius: 10px;
}

.is-scrolling::-webkit-scrollbar-thumb {
  background-color: var(--color-blue-300);
  border-radius: 10px;
}

@media only screen and (max-width: 1280px) {
  .is-scrolling {
    padding: 15px 0;
    overflow-x: scroll;
  }
}

.hidden {
  opacity: 0;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible:focus:not(.focus-visible) {
  outline: none;
}

h1, h2 {
  font-family: var(--font-family-secondary);
  font-weight: var(--fw-700);
  font-size: clamp(2.125rem, 1.5rem + 2.7778vi, 4rem);
  line-height: 1.2;
  overflow: hidden;
}

h2 {
  letter-spacing: .006em;
  margin-bottom: 16px;
  font-size: clamp(1.75rem, 1.5rem + 1.1111vi, 2.5rem);
  line-height: 1.42;
}

h3, .subtitle {
  font-weight: var(--fw-600);
  letter-spacing: .01em;
  color: var(--color-black);
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 1.5;
}

.subtitle {
  font-family: var(--font-family-third);
  font-weight: var(--fw-700);
  letter-spacing: .01em;
  color: var(--color-orange-600);
  font-size: clamp(1.125rem, 1rem + .5556vi, 1.5rem);
  line-height: 1.08;
}

p {
  font-size: clamp(16px, .9167rem + .3704vi, 20px);
}

.header {
  z-index: 10;
  width: 100%;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: #394264cc;
  padding: 16px 0;
  transition-property: background-color, transform;
  transition-duration: .25s;
  position: fixed;
  top: 0;
}

.header.hide {
  transform: translateY(-200%);
}

.header.scroll {
  border-bottom: 1px solid var(--color-white);
  background-color: var(--color-blue-600);
}

.header.active {
  background-color: var(--color-blue-600);
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav__inner {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.nav__list {
  align-items: center;
  margin-right: 36px;
  display: flex;
}

.nav__item:not(:last-of-type) {
  margin-right: 36px;
}

.nav__link {
  font-size: 18px;
  line-height: 1.25;
  position: relative;
}

.nav__link:before {
  content: "";
  width: 100%;
  height: 2px;
  transform-origin: 0;
  background-color: currentColor;
  transition: transform .3s linear;
  position: absolute;
  bottom: -2px;
  left: 0;
  transform: scale(0);
}

.nav__link:hover:before, .nav__link.true:before {
  transform: scale(1);
}

.nav__btns {
  align-items: center;
  gap: 18px;
  display: flex;
}

.nav--footer {
  margin-bottom: 48px;
}

@media only screen and (max-width: 992px) {
  .nav__inner {
    z-index: -1;
    padding-top: calc(var(--header-height) * 1.2);
    width: 100%;
    height: var(--vh);
    max-width: 350px;
    background-color: var(--color-blue-600);
    flex-direction: column;
    justify-content: flex-start;
    transition-property: transform;
    transition-duration: .25s;
    position: fixed;
    top: 0;
    right: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }

  .nav__list {
    z-index: -1;
    flex-direction: column;
    margin: 0;
  }

  .nav__item {
    margin-bottom: 30px;
  }

  .nav__item:not(:last-child) {
    margin-right: 0;
  }

  .nav__link {
    font-size: 20px;
  }

  .nav__btns {
    flex-direction: column;
    gap: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .nav--footer {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    margin-bottom: 24px;
  }
}

[class].btn {
  border-radius: var(--radius-main);
  width: 100%;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-family: inherit;
  font-weight: var(--fw-500);
  color: var(--color-white);
  background-color: var(--color-blue-300);
  border: 1px solid #0000;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 7px 31px;
  font-size: 18px;
  line-height: 1.5;
  transition-property: background-color, color, border-color;
  transition-duration: .3s;
  display: flex;
}

[class].btn:hover, [class].btn:focus {
  border-color: var(--color-blue-300);
  color: var(--color-blue-300);
  background-color: var(--color-white);
}

[class].btn--reverse {
  color: var(--color-black);
  background-color: var(--color-white);
}

[class].btn--reverse:hover, [class].btn--reverse:focus {
  color: var(--color-white);
  background-color: var(--color-blue-300);
}

[class].btn--request {
  background-color: var(--color-orange-600);
}

[class].btn--request:hover, [class].btn--request:focus {
  color: var(--color-orange-600) !important;
}

[class].btn--form {
  font-family: var(--font-family-third);
  font-weight: var(--fw-700);
  letter-spacing: .01em;
  border-radius: 4px;
  gap: 4px;
  padding: 6px 22px 6px 18px;
  font-size: 14px;
  line-height: 2;
  position: relative;
}

[class].btn--form:before {
  content: "";
  width: 24px;
  height: 24px;
  background-color: currentColor;
  transition: background-color .3s linear;
  position: relative;
  -webkit-mask-image: url("../images/icons/plain.svg");
  mask-image: url("../images/icons/plain.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.burger {
  --line-height: 2px;
  --burger-size: 20px;
  --move: calc(var(--burger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-white);
  width: calc(var(--burger-size)  + 5px);
  height: var(--burger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy__content:not(:last-child) {
  margin-bottom: 24px;
}

.policy h1 {
  letter-spacing: .006em;
  color: var(--color-orange-600);
  margin-bottom: 3rem;
  font-size: clamp(1.75rem, 1.5rem + 1.1111vi, 2.5rem);
  line-height: 1.42;
}

.policy h2 {
  font-weight: var(--fw-600);
  color: var(--color-blue-300);
  margin-bottom: 24px;
  font-size: clamp(1.125rem, .875rem + 1.1111vi, 1.875rem);
}

.policy ul {
  margin-bottom: 20px;
  padding-left: 15px;
}

.policy li {
  padding-left: 10px;
  font-size: clamp(16px, .9167rem + .3704vi, 20px);
}

.policy li:not(:last-child) {
  margin-bottom: 3px;
}

.policy li::marker {
  content: "✓";
}

.policy p:not(:last-child) {
  margin-bottom: 20px;
}

.policy a {
  color: var(--color-blue-300);
  text-decoration: underline;
}

@media only screen and (max-width: 992px) {
  .policy h1 {
    margin-bottom: 30px;
  }
}

.footer {
  background-color: var(--color-blue-900);
  padding: 40px 0;
}

.footer__list {
  gap: 24px;
  display: flex;
}

.footer__list a {
  font-size: 16px;
  transition: color .3s linear;
}

.footer__list a:hover {
  color: var(--color-blue-300);
}

.footer__copy {
  justify-content: space-between;
  display: flex;
}

.footer__copy p {
  font-size: 16px;
}

.footer__copy a {
  align-items: center;
  gap: 8px;
  display: flex;
}

.footer__copy a:before {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("../images/icons/mail.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

@media only screen and (max-width: 992px) {
  .footer {
    padding: 24px 0;
  }

  .footer__list {
    gap: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .footer__list {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer__copy {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}

.contacts {
  padding: 6.25rem 0;
  position: relative;
}

.contacts:before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--color-white);
  opacity: .3;
  position: absolute;
  top: 0;
}

.contacts__wrapper {
  max-width: 675px;
}

.contacts__form {
  width: 100%;
  max-width: 100%;
}

.contacts__form label {
  width: 100%;
}

.contacts__form input {
  font-family: var(--font-family-third);
  letter-spacing: .02em;
  opacity: .6;
  padding-left: 40px;
  font-size: 12px;
  line-height: 1.33;
}

.contacts__form input[type="email"] {
  background-image: url("../images/icons/email.svg");
  background-position: 5px -5px;
  background-repeat: no-repeat;
}

.contacts__form .btn {
  margin: 0 auto;
}

.contacts__inner {
  border-radius: var(--radius-main);
  background-color: var(--color-white);
  align-items: center;
  padding: 15px 13px 15px 24px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 13px 19px #00000012;
}

.contacts h2 {
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  margin-bottom: 56px;
}

@media only screen and (max-width: 992px) {
  .contacts {
    padding: 50px 0;
  }

  .contacts__wrapper {
    max-width: 100%;
  }

  .contacts h2 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 576px) {
  .contacts {
    padding: 30px 0;
  }
}

.hero {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero__inner {
  min-height: 901px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  display: flex;
  position: relative;
}

.hero__inner:before {
  content: "";
  z-index: 1;
  background: linear-gradient(#39426400 87.858%, #394264 98.371%);
  position: absolute;
  inset: 0;
}

.hero__content {
  z-index: 1;
  width: 100%;
  max-width: 613px;
  position: relative;
  translate: 0 -6px;
}

.hero__content:before {
  content: "";
  z-index: -1;
  filter: blur(99px);
  background: #00000052;
  position: absolute;
  inset: 0;
}

.hero--home .hero__content {
  translate: 0;
}

.hero--home .hero__content h1 {
  margin-bottom: 24px;
  line-height: .89;
}

.hero--services .hero__content {
  max-width: 615px;
}

.hero--services h1 {
  font-size: clamp(2.125rem, .375rem + 3.3333vi, 3.375rem);
}

.hero h1 {
  margin-bottom: 14px;
}

.hero p {
  width: 100%;
  max-width: 533px;
  font-weight: var(--fw-500);
  font-size: clamp(1.125rem, 1rem + .5556vi, 1.5rem);
  line-height: 1.38;
}

@media only screen and (max-width: 992px) {
  .hero__inner {
    min-height: 450px;
  }

  .hero__inner:before {
    opacity: .6;
    background: #394264 98.371%;
  }

  .hero__content, .hero p {
    max-width: 100%;
  }

  .hero__content:before, .hero p:before {
    content: none;
  }

  .hero--services .hero__content {
    max-width: 100%;
    translate: 0;
  }
}

@media only screen and (max-width: 576px) {
  .hero__inner {
    min-height: 400px;
  }

  .hero .btn {
    max-width: 100%;
  }
}

.faq__content {
  margin-bottom: 4.5rem;
}

.faq__item {
  position: relative;
}

.faq__item:before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--color-white);
  opacity: .3;
  position: absolute;
  bottom: -24px;
}

.faq__item:not(:last-child) {
  margin-bottom: 48px;
}

.faq details[open] summary {
  color: var(--color-blue-300);
}

.faq details[open] summary:after {
  transform: scaleY(1);
}

.faq summary {
  font-weight: var(--fw-600);
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding-right: 25px;
  font-size: clamp(1.125rem, .875rem + 1.1111vi, 1.875rem);
  line-height: 1.5;
  transition: color .3s linear;
  display: flex;
}

.faq summary:after {
  content: "";
  width: 32px;
  height: 32px;
  background-color: currentColor;
  transition: transform .3s linear;
  position: absolute;
  top: 0;
  right: 0;
  transform: scaleY(-1);
  -webkit-mask-image: url("../images/icons/chevron.svg");
  mask-image: url("../images/icons/chevron.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.faq summary + div {
  color: var(--color-wite);
  padding: 16px 0 0;
}

.faq summary:hover {
  color: var(--color-blue-300);
}

.faq h2 {
  color: var(--color-orange-600);
  margin-bottom: 27px;
}

@media only screen and (max-width: 1180px) {
  .faq__content {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 992px) {
  .faq {
    padding-bottom: 45px;
  }

  .faq__content {
    margin-bottom: 30px;
  }

  .faq__item:not(:last-child) {
    margin-bottom: 24px;
  }

  .faq__item:not(:last-child):before {
    bottom: -14px;
  }
}

.story h2 {
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  margin: 0 auto 48px;
}

.story__inner {
  width: 100%;
  max-width: 1090px;
  align-items: center;
  gap: 24px;
  margin-inline: auto;
  display: flex;
}

.story__desc {
  width: 100%;
  max-width: 644px;
  translate: 0 4px;
}

.story__desc p {
  margin-bottom: 24px;
}

.story .btn {
  margin-left: auto;
  padding: 18px 36px;
}

.story--about {
  padding-top: 56px;
}

.story--about .story__inner {
  flex-direction: row-reverse;
}

.story--about .story__desc {
  max-width: 532px;
}

.story--about .story__desc p {
  margin-bottom: 0;
}

@media only screen and (max-width: 992px) {
  .story__inner {
    flex-direction: column;
    align-items: stretch;
  }

  .story__desc {
    max-width: 100%;
    translate: 0;
  }

  .story__image img {
    width: 100%;
    object-position: center;
    aspect-ratio: 16 / 9;
  }

  .story h2 {
    margin-bottom: 30px;
  }

  .story .btn {
    min-width: 280px;
    margin-inline: auto;
  }

  .story--about {
    padding: 30px 0;
  }

  .story--about .story__inner {
    flex-direction: column;
    align-items: stretch;
  }

  .story--about .story__desc {
    max-width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .story .btn {
    max-width: 100%;
  }
}

.tabs__nav {
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-bottom: 3rem;
  display: flex;
}

.tabs__link {
  white-space: nowrap;
  opacity: .6;
  font-size: clamp(16px, .9167rem + .3704vi, 20px);
  transition: opacity .3s linear;
}

.tabs__link:hover {
  opacity: 1;
}

.tabs__link.selected {
  font-weight: var(--fw-600);
  opacity: 1;
  font-size: 22px;
  line-height: 1.55;
}

.tabs__content {
  width: 100%;
  max-width: 866px;
  margin-inline: auto;
}

.tabs__content p {
  margin-bottom: 35px;
}

.tabs__image, .tabs__covers {
  margin-bottom: 36px;
}

.tabs__covers p {
  margin-bottom: 8px;
}

.tabs__benefits {
  gap: 24px;
  display: flex;
}

.tabs__text {
  width: 100%;
  max-width: 532px;
}

.tabs ul:not([class]) {
  gap: 5px;
  display: grid;
}

.tabs ul:not([class]) li {
  padding-left: 30px;
  font-size: clamp(16px, .9167rem + .3704vi, 20px);
  position: relative;
}

.tabs ul:not([class]) li:before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: var(--color-white);
  border-radius: 50%;
  position: absolute;
  top: 13px;
  left: 13px;
}

.tabs img {
  border-radius: calc(var(--radius-main)  + 4px);
  width: 100%;
  overflow: hidden;
}

.tabs h3 {
  color: var(--color-orange-600);
  margin-bottom: 16px;
  font-size: clamp(1.125rem, 1rem + .5556vi, 1.5rem);
}

@media only screen and (max-width: 1280px) {
  .tabs__nav {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 992px) {
  .tabs__nav {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .tabs__benefits {
    flex-direction: column;
  }

  .tabs__link.selected {
    font-size: 20px;
  }

  .tabs img {
    aspect-ratio: 16 / 9;
  }
}

.options {
  padding: 3.625rem 0 3.5rem;
}

.options__content {
  margin-bottom: 42px;
}

.options__content p {
  font-family: var(--font-family-third);
  font-weight: var(--fw-300);
  letter-spacing: .01em;
  color: var(--color-white);
  font-size: 16px;
  line-height: 1.25;
}

.options__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 23px;
  margin-bottom: 3rem;
  display: grid;
}

.options__item {
  border-radius: var(--radius-big);
  background-color: var(--color-white);
  align-items: center;
  gap: 24px;
  padding: 24px;
  display: flex;
  overflow: hidden;
}

.options__item p {
  margin-bottom: 16px;
}

.options__item img {
  border-radius: calc(var(--radius-main)  + 4px);
  overflow: hidden;
}

.options__desc {
  width: 100%;
  max-width: 167px;
  font-family: var(--font-family-third);
  font-weight: var(--fw-300);
  letter-spacing: .02em;
  color: var(--color-black);
  font-size: 16px;
  line-height: 1.25;
}

.options__desc p {
  margin-bottom: 12px;
  font-size: 16px;
}

.options__value span, .options__value li {
  font-size: 15px;
  line-height: 1.33;
}

.options__value span {
  margin-bottom: 8px;
}

.options__value li {
  padding-left: 24px;
  position: relative;
}

.options__value li:before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: var(--color-black);
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: 10px;
}

.options h3 {
  margin-bottom: 10px;
}

.options .btn {
  margin-inline: auto;
  padding: 22px 90px;
}

@media only screen and (max-width: 992px) {
  .options {
    padding: 30px 0;
  }

  .options__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .options__item {
    padding: 16px;
  }

  .options__item:last-child {
    max-width: 100%;
    grid-column: 2 span;
  }

  .options__item:last-child .options__desc {
    max-width: 100%;
  }

  .options__content {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .options__list {
    grid-template-columns: 1fr;
  }

  .options__item:last-child {
    grid-column: auto;
  }

  .options__desc {
    max-width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .options .btn {
    max-width: 100%;
  }
}

.reviews__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  display: grid;
}

.reviews__item {
  border-radius: var(--radius-main);
  background-color: var(--color-white);
  align-content: baseline;
  place-items: center;
  padding: 24px;
  display: grid;
  overflow: hidden;
}

.reviews__item p {
  color: var(--color-black);
  font-size: 18px;
}

.reviews__autor {
  text-align: center;
}

.reviews__autor:after {
  content: "";
  width: 181px;
  height: 24px;
  background-image: url("../images/icons/stars.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 24px;
  display: block;
  position: relative;
}

.reviews img {
  border-radius: 50%;
  margin-bottom: 27px;
  overflow: hidden;
}

.reviews h3 {
  margin-bottom: 24px;
}

@media only screen and (max-width: 992px) {
  .reviews {
    padding: 30px 0;
  }

  .reviews__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .reviews__item {
    padding: 16px;
  }

  .reviews__item img {
    margin-bottom: 16px;
  }

  .reviews__item:last-child {
    max-width: 100%;
    grid-column: 2 span;
  }
}

@media only screen and (max-width: 576px) {
  .reviews__list {
    grid-template-columns: 1fr;
  }

  .reviews__item:last-child {
    grid-column: auto;
  }
}

.values {
  padding: 3.75rem 0 3rem;
}

.values__list {
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  display: grid;
}

.values__item {
  border-radius: calc(var(--radius-big)  + 12px);
  color: var(--color-black);
  background-color: var(--color-white);
  padding: 24px;
  overflow: hidden;
}

.values__item:before {
  content: "";
  width: 48px;
  height: 48px;
  background-image: url("../images/icons/hands.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 24px;
  display: block;
  position: relative;
}

.values__item--customer:before {
  background-image: url("../images/icons/like.svg");
}

.values__item--innovation:before {
  background-image: url("../images/icons/light.svg");
}

.values__item--community:before {
  background-image: url("../images/icons/group.svg");
}

.values h2 {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 27px;
}

.values h3 {
  margin-bottom: 24px;
}

.values p {
  opacity: .8;
  font-size: 18px;
}

@media only screen and (max-width: 1180px) {
  .values__item {
    padding: 16px;
  }
}

@media only screen and (max-width: 1180px) and (max-width: 992px) {
  .values {
    padding: 30px 0;
  }

  .values__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .values__item:before, .values__item h3 {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 1180px) and (max-width: 576px) {
  .values__list {
    grid-template-columns: 1fr;
  }
}

.missions {
  padding-bottom: 3.75rem;
}

.missions__inner {
  width: 100%;
  max-width: 1090px;
  margin-inline: auto;
}

.missions h2 {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 24px;
}

@media only screen and (max-width: 992px) {
  .missions {
    padding-bottom: 30px;
  }
}

.team__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  display: grid;
}

.team__item {
  border-radius: calc(var(--radius-big)  + 12px);
  overflow: hidden;
}

.team__desc {
  height: 100%;
  color: var(--color-black);
  background-color: var(--color-white);
  flex-direction: column;
  padding: 24px;
  display: flex;
  overflow: hidden;
}

.team__desc span {
  color: var(--color-blue-300);
  margin-bottom: 26px;
  display: block;
}

.team__desc p {
  font-size: 18px;
}

.team img {
  border-radius: var(--radius-big);
  width: 100%;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  overflow: hidden;
}

.team h3 {
  margin-bottom: 8px;
}

@media only screen and (max-width: 992px) {
  .team__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .team__item:last-child {
    grid-column: span 2;
  }

  .team__item:last-child img {
    aspect-ratio: 2 / 1;
  }

  .team__desc {
    padding: 16px;
  }

  .team__desc span {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 576px) {
  .team__list {
    grid-template-columns: 1fr;
  }

  .team__item img {
    object-position: center;
    aspect-ratio: 2 / 1;
  }

  .team__item:last-child {
    grid-column: auto;
  }
}

/*# sourceMappingURL=main.css.map */
