@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.missions {
  padding-bottom: rem(60);

  &__inner {
    width: 100%;
    max-width: 1090px;
    margin-inline: auto;
  }

  h2 {
    margin: 0 auto 24px;
    width: fit-content;
  }

  @include media(tablet) {
    padding-bottom: 30px;
  }
}
