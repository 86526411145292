@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.header {
  position: fixed;
  top: 0;
  z-index: 10;
  padding: 16px 0;
  width: 100%;
  background-color: rgba(57, 66, 100, 0.8);
  transition-property: background-color, transform;
  transition-duration: 0.25s;
  backdrop-filter: blur(8px);

  &.hide {
    transform: translateY(-200%);
  }

  &.scroll {
    border-bottom: 1px solid var(--color-white);
    background-color: var(--color-blue-600);
  }

  &.active {
    background-color: var(--color-blue-600);
  }
}
