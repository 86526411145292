@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.contacts {
  position: relative;
  padding: rem(100) 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 1px;
    background-color: var(--color-white);
    opacity: 0.3;
  }

  &__wrapper {
    max-width: 675px;
  }

  &__form {
    width: 100%;
    max-width: 100%;

    label {
      width: 100%;
    }

    input {
      padding-left: 40px;
      font-family: var(--font-family-third);
      font-size: 12px;
      line-height: 1.33;
      letter-spacing: 0.02em;
      opacity: 0.6;
    }

    input[type="email"] {
      background-image: url("../images/icons/email.svg");
      background-position: 5px -5px;
      background-repeat: no-repeat;
    }

    .btn {
      margin: 0 auto;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: var(--radius-main);
    padding: 15px 13px 15px 24px;
    box-shadow: 0 13px 19px 0 rgba(0, 0, 0, 0.07);
    background-color: var(--color-white);
  }

  h2 {
    margin-bottom: 56px;
    width: fit-content;
    text-align: center;
  }

  @include media(tablet) {
    padding: 50px 0;

    &__wrapper {
      max-width: 100%;
    }

    h2 {
      margin-bottom: 30px;
    }
  }

  @include media(mobile-m) {
    padding: 30px 0;
  }

}
