@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

.policy {
  &__content {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  h1 {
    margin-bottom: rem(48);
    font-size: clamp(1.75rem, 1.5rem + 1.1111vi, 2.5rem);
    line-height: 1.42;
    letter-spacing: 0.006em;
    color: var(--color-orange-600);
  }

  h2 {
    margin-bottom: 24px;
    font-weight: var(--fw-600);
    font-size: clamp(1.125rem, 0.875rem + 1.1111vi, 1.875rem);
    color: var(--color-blue-300);
  }

  ul {
    margin-bottom: 20px;
    padding-left: 15px;
  }

  li {
    padding-left: 10px;
    font-size: clamp(16px, 0.9167rem + 0.3704vi, 20px);

    &:not(:last-child) {
      margin-bottom: 3px;
    }

    &::marker {
      content: "✓";
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  a {
    text-decoration: underline;
    color: var(--color-blue-300);
  }

  @include media(tablet) {
    h1 {
      margin-bottom: 30px;
    }
  }
}
