@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.hero {
  $root: &;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    min-height: 901px;

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      background: linear-gradient(180deg, rgba(57, 66, 100, 0) 87.858%, rgb(57, 66, 100) 98.371%);
      inset: 0;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 613px;
    translate: 0 -6px;

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      filter: blur(99px);
      background: rgba(0, 0, 0, 0.32);
      inset: 0;
    }
  }

  &--home {
    #{$root}__content {
      translate: none;

      h1 {
        margin-bottom: 24px;
        line-height: 0.89;
      }
    }
  }

  &--services {
    #{$root}__content {
      max-width: 615px;
    }

    h1 {
      font-size: clamp(2.125rem, 0.375rem + 3.3333vi, 3.375rem);
    }
  }

  h1 {
    margin-bottom: 14px;
  }

  p {
    width: 100%;
    max-width: 533px;
    font-weight: var(--fw-500);
    font-size: clamp(1.125rem, 1rem + 0.5556vi, 1.5rem);
    line-height: 1.38;
  }

  @include media(tablet) {
    &__inner {
      min-height: 450px;

      &::before {
        background: rgb(57, 66, 100) 98.371%;
        opacity: 0.6;
      }
    }

    &__content,
    p {
      max-width: 100%;

      &::before {
        content: none;
      }
    }

    &--services {
      #{$root}__content {
        max-width: 100%;
        translate: none;
      }
    }
  }

  @include media(mobile-m) {
    &__inner {
      min-height: 400px;
    }

    .btn {
      max-width: 100%;
    }
  }
}
