@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.faq {
  &__content {
    margin-bottom: rem(72);
  }

  &__item {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: -24px;
      width: 100%;
      height: 1px;
      background-color: var(--color-white);
      opacity: 0.3;
    }

    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }

  details {
    &[open] {
      summary {
        color: var(--color-blue-300);

        &::after {
          transform: scaleY(1);
        }
      }
    }
  }

  summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 25px;
    font-weight: var(--fw-600);
    font-size: clamp(1.125rem, 0.875rem + 1.1111vi, 1.875rem);
    line-height: 1.5;
    transition: color 0.3s linear;
    cursor: pointer;

    &::after {
      @include mask;

      right: 0;
      top: 0;
      mask-image: url("../images/icons/chevron.svg");
      width: 32px;
      height: 32px;
      transform: scaleY(-1);
      transition: transform 0.3s linear;
    }

    + div {
      padding: 16px 0 0;
      color: var(--color-wite);
    }

    &:hover {
      color: var(--color-blue-300);
    }
  }

  h2 {
    margin-bottom: 27px;
    color: var(--color-orange-600);
  }

  @include media(tablet-wide) {
    &__content {
      margin-bottom: 40px;
    }
  }

  @include media(tablet) {
    padding-bottom: 45px;

    &__content {
      margin-bottom: 30px;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 24px;

        &::before {
          bottom: -14px;
        }
      }
    }
  }
}
