@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.tabs {
  &__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(48);
    gap: 24px;
  }

  &__link {
    font-size: clamp(16px, 0.9167rem + 0.3704vi, 20px);
    white-space: nowrap;
    opacity: 0.6;
    transition: opacity 0.3s linear;

    &:hover {
      opacity: 1;
    }

    &.selected {
      font-weight: var(--fw-600);
      font-size: 22px;
      line-height: 1.55;
      opacity: 1;
    }
  }

  &__content {
    width: 100%;
    max-width: 866px;
    margin-inline: auto;

    p {
      margin-bottom: 35px;
    }
  }

  &__image {
    margin-bottom: 36px;
  }

  &__covers {
    margin-bottom: 36px;

    p {
      margin-bottom: 8px;
    }
  }

  &__benefits {
    display: flex;
    gap: 24px;
  }

  &__text {
    width: 100%;
    max-width: 532px;
  }

  ul:not([class]) {
    display: grid;
    gap: 5px;

    li {
      position: relative;
      padding-left: 30px;
      font-size: clamp(16px, 0.9167rem + 0.3704vi, 20px);

      &::before {
        content: "";
        position: absolute;
        left: 13px;
        top: 13px;
        border-radius: 50%;
        width: 4px;
        height: 4px;
        background-color: var(--color-white);
      }
    }
  }

  img {
    overflow: hidden;
    border-radius: calc(var(--radius-main) + 4px);
    width: 100%;
  }

  h3 {
    margin-bottom: 16px;
    font-size: clamp(1.125rem, 1rem + 0.5556vi, 1.5rem);
    color: var(--color-orange-600);
  }

  @include media(desktop) {
    &__nav {
      justify-content: flex-start;
    }
  }

  @include media(tablet) {
    &__nav {
      margin-bottom: 30px;
    }
  }

  @include media(mobile-l) {
    &__benefits {
      flex-direction: column;
    }

    &__link {
      &.selected {
        font-size: 20px;
      }
    }

    img {
      aspect-ratio: 16/9;
    }
  }
}
