@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.values {
  padding: rem(60) 0 rem(48);

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
  }

  &__item {
    overflow: hidden;
    border-radius: calc(var(--radius-big) + 12px);
    padding: 24px;
    color: var(--color-black);
    background-color: var(--color-white);

    &::before {
      content: "";
      position: relative;
      display: block;
      margin-bottom: 24px;
      width: 48px;
      height: 48px;
      background-image: url("../images/icons/hands.svg");
      background-position: 0 0;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &--customer {
      &::before {
        background-image: url("../images/icons/like.svg");
      }
    }

    &--innovation {
      &::before {
        background-image: url("../images/icons/light.svg");
      }
    }

    &--community {
      &::before {
        background-image: url("../images/icons/group.svg");
      }
    }
  }

  h2 {
    margin: 0 auto 27px;
    width: fit-content;
  }

  h3 {
    margin-bottom: 24px;
  }

  p {
    font-size: 18px;
    opacity: 0.8;
  }

  @include media(tablet-wide) {
    &__item {
      padding: 16px;
    }

    @include media(tablet) {
      padding: 30px 0;

      &__list {
        grid-template-columns: repeat(2, 1fr);
      }

      &__item {
        &::before {
          margin-bottom: 16px;
        }

        h3 {
          margin-bottom: 16px;
        }
      }
    }

    @include media(mobile-m) {
      &__list {
        grid-template-columns: 1fr;
      }
    }
  }
}
