@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.options {
  padding: rem(58) 0 rem(56);

  &__content {
    margin-bottom: 42px;

    p {
      font-family: var(--font-family-third);
      font-weight: var(--fw-300);
      font-size: 16px;
      line-height: 1.25;
      letter-spacing: 0.01em;
      color: var(--color-white);
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: rem(48);
    gap: 23px;
  }

  &__item {
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: var(--radius-big);
    padding: 24px;
    background-color: var(--color-white);
    gap: 24px;

    p {
      margin-bottom: 16px;
    }

    img {
      overflow: hidden;
      border-radius: calc(var(--radius-main) + 4px);
    }
  }

  &__desc {
    width: 100%;
    max-width: 167px;
    font-family: var(--font-family-third);
    font-weight: var(--fw-300);
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 0.02em;
    color: var(--color-black);

    p {
      font-size: 16px;
      margin-bottom: 12px;
    }
  }

  &__value {
    span,
    li {
      font-size: 15px;
      line-height: 1.33;
    }

    span {
      margin-bottom: 8px;
    }

    li {
      position: relative;
      padding-left: 24px;

      &::before {
        content: "";
        position: absolute;
        left: 10px;
        top: 7px;
        border-radius: 50%;
        width: 4px;
        height: 4px;
        background-color: var(--color-black);
      }
    }
  }

  h3 {
    margin-bottom: 10px;
  }

  .btn {
    padding: 22px 90px;
    margin-inline: auto;
  }

  @include media(tablet) {
    padding: 30px 0;

    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      padding: 16px;

      &:last-child {
        grid-column: 2 span;
        max-width: 100%;

        .options__desc {
          max-width: 100%;
        }
      }
    }

    &__content {
      margin-bottom: 30px;
    }
  }

  @include media(mobile-l) {
    &__list {
      grid-template-columns: 1fr;
    }

    &__item {
      &:last-child {
        grid-column: auto;
      }
    }

    &__desc {
      max-width: 100%;
    }
  }

  @include media(mobile-m) {
    .btn {
      max-width: 100%;
    }
  }
}
