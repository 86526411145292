@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

h1,
h2 {
  overflow: hidden;
  font-family: var(--font-family-secondary);
  font-weight: var(--fw-700);
  font-size: clamp(2.125rem, 1.5rem + 2.7778vi, 4rem);
  line-height: 1.2;
}

h2 {
  margin-bottom: 16px;
  font-size: clamp(1.75rem, 1.5rem + 1.1111vi, 2.5rem);
  line-height: 1.42;
  letter-spacing: 0.006em;
}

h3,
.subtitle {
  margin-bottom: 16px;
  font-weight: var(--fw-600);
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: var(--color-black);
}

.subtitle {
  font-family: var(--font-family-third);
  font-weight: var(--fw-700);
  font-size: clamp(1.125rem, 1rem + 0.5556vi, 1.5rem);
  line-height: 1.08;
  letter-spacing: 0.01em;
  color: var(--color-orange-600);
}

p {
  font-size: clamp(16px, 0.9167rem + 0.3704vi, 20px);
}
