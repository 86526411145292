/* stylelint-disable declaration-no-important */
@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

*[class].btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border: 1px solid transparent;
  border-radius: var(--radius-main);
  padding: 7px 31px;
  width: 100%;
  max-width: fit-content;
  font-family: inherit;
  font-weight: var(--fw-500);
  font-size: 18px;
  line-height: 1.5;
  color: var(--color-white);
  background-color: var(--color-blue-300);
  transition-property: background-color, color, border-color;
  transition-duration: 0.3s;

  &:hover,
  &:focus {
    border-color: var(--color-blue-300);
    color: var(--color-blue-300);
    background-color: var(--color-white);
  }

  &--reverse {
    color: var(--color-black);
    background-color: var(--color-white);

    &:hover,
    &:focus {
      color: var(--color-white);
      background-color: var(--color-blue-300);
    }
  }

  &--request {
    background-color: var(--color-orange-600);

    &:hover,
    &:focus {
      color: var(--color-orange-600) !important;
    }
  }

  &--form {
    position: relative;
    border-radius: 4px;
    padding: 6px 22px 6px 18px;
    font-family: var(--font-family-third);
    font-weight: var(--fw-700);
    font-size: 14px;
    line-height: 2;
    letter-spacing: 0.01em;
    gap: 4px;

    &::before {
      @include mask;

      position: relative;
      mask-image: url("../images/icons/plain.svg");
      width: 24px;
      height: 24px;
    }
  }
}
