/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */

@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --content-width: 1312px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Poppins", sans-serif;
  --font-family-secondary: "Merriweather", sans-serif;
  --font-family-third: "Oswald", sans-serif;
  // font-weight
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --fw-900: 900;

  //
  --color-white: #fff;
  --color-black: #000;
  --color-blue-300: #31a0fee5;
  --color-blue-600: #394264;
  --color-blue-900: #252b42;
  --color-orange-600: #ec5c2e;

  //  radius
  --radius-main: 12px;
  --radius-big: 20px;
}
